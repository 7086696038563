/* generated by pull.js */
const manifest = {
  "name": "Multiple rows in backpack",
  "description": "Arranges the contents of the editor backpack into an expandable two-way grid, similar to the costume library and increases the size of certain thumbnails.",
  "credits": [
    {
      "name": "pumpkinhasapatch"
    }
  ],
  "tags": [
    "new"
  ],
  "enabledByDefault": false,
  "settings": [
    {
      "dynamic": true,
      "name": "Number of displayed rows",
      "id": "rows",
      "type": "integer",
      "min": 1,
      "max": 4,
      "default": 2
    },
    {
      "dynamic": true,
      "name": "Larger costume and script thumbnails",
      "id": "upscale",
      "type": "boolean",
      "default": true
    }
  ],
  "customCssVariables": [
    {
      "name": "imgsize",
      "value": {
        "type": "ternary",
        "source": {
          "type": "settingValue",
          "settingId": "upscale"
        },
        "true": "95%",
        "false": "32px"
      }
    },
    {
      "name": "bgcolor",
      "value": {
        "type": "ternary",
        "source": {
          "type": "settingValue",
          "settingId": "upscale"
        },
        "true": "var(--ui-white)",
        "false": "transparent"
      }
    }
  ],
  "userstyles": [
    {
      "url": "backpack.css"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true
};
export default manifest;
