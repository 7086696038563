/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Block switching",
  "description": "Right click on a block to switch it to a related block.",
  "credits": [
    {
      "name": "GarboMuffin"
    },
    {
      "name": "pufferfish101007",
      "link": "https://scratch.mit.edu/users/pufferfish101007/"
    }
  ],
  "dynamicDisable": true,
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "tags": [
    "recommended"
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Motion blocks",
      "id": "motion",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Looks blocks",
      "id": "looks",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Sound blocks",
      "id": "sound",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Event blocks",
      "id": "event",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Control blocks",
      "id": "control",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Operator blocks",
      "id": "operator",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Sensing blocks",
      "id": "sensing",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Data blocks",
      "id": "data",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Extension blocks",
      "id": "extension",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Addon blocks",
      "id": "sa",
      "type": "boolean",
      "default": true,
      "if": {
        "addonEnabled": [
          "debugger"
        ]
      }
    },
    {
      "dynamic": true,
      "name": "Custom block parameters",
      "id": "customargs",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Shown custom block parameters options",
      "id": "customargsmode",
      "type": "select",
      "default": "defOnly",
      "potentialValues": [
        {
          "id": "defOnly",
          "name": "Parameters in own custom block"
        },
        {
          "id": "all",
          "name": "Parameters in all custom blocks in sprite"
        }
      ],
      "if": {
        "settings": {
          "customargs": true
        }
      }
    },
    {
      "dynamic": true,
      "name": "Show option to change block to self",
      "id": "noop",
      "type": "boolean",
      "default": true
    }
  ],
  "enabledByDefault": true
};
export default manifest;
