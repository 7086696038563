/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Non-draggable sprites in editor",
  "description": "Removes the ability to drag sprites around on the stage in the editor, except those explicitly set as draggable. Hold Shift while dragging a sprite to move it normally.",
  "credits": [
    {
      "name": "Chrome_Cat",
      "link": "https://scratch.mit.edu/users/Chrome_Cat/"
    },
    {
      "name": "GarboMuffin"
    },
    {
      "name": "Mr_MPH",
      "link": "https://scratch.mit.edu/users/Mr_MPH/"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "settings": [
    {
      "name": "Allow dragging while project is stopped",
      "id": "drag_while_stopped",
      "type": "boolean",
      "default": false
    }
  ],
  "tags": [],
  "enabledByDefault": false,
  "dynamicDisable": true
};
export default manifest;
