/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Move sprite to front layer",
  "description": "Shift+Click a sprite within the sprite pane to move it to the front (top layer) of the stage.",
  "credits": [
    {
      "name": "Norbiros",
      "link": "https://scratch.mit.edu/users/Norbir/"
    },
    {
      "name": "s_federici",
      "link": "https://scratch.mit.edu/users/s_federici/"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true,
  "tags": []
};
export default manifest;
