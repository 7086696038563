/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Sprite deletion confirmation",
  "description": "Asks if you're sure when deleting a sprite inside a project.",
  "info": [
    {
      "text": "Tip: If you accidentally delete a sprite, costume, or sound, you can undo the deletion by clicking Edit in the menu bar then Restore.",
      "id": "restoretip"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "dynamicDisable": true,
  "tags": [
    "recommended"
  ],
  "enabledByDefault": false
};
export default manifest;
