/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Debugger",
  "description": "Adds a new \"debugger\" window to the editor. Allows for logging into the \"Logs\" tab of the debugger window using the \"log\", \"warn\" and \"error\" blocks. The \"breakpoint\" block will pause the project when executed. All running stacks of blocks can be viewed in the \"Threads\" tab of the debugger window, and when paused the \"Step\" button can be used to execute the next block. A graph of frames per second and number of clones can be viewed in the \"Performance\" tab.",
  "credits": [
    {
      "name": "Tacodiva",
      "link": "https://scratch.mit.edu/users/Tacodiva7729/"
    },
    {
      "name": "GarboMuffin"
    },
    {
      "name": "GrahamSH"
    },
    {
      "name": "TheColaber"
    },
    {
      "name": "retronbv"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "style.css"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Clear logs on green flag",
      "id": "log_clear_greenflag",
      "type": "boolean",
      "default": false
    },
    {
      "dynamic": true,
      "name": "Log green flag clicks",
      "id": "log_greenflag",
      "type": "boolean",
      "default": false
    },
    {
      "dynamic": true,
      "name": "Log clone creation",
      "id": "log_clone_create",
      "type": "boolean",
      "default": false
    },
    {
      "name": "Log when clone limit exceeded",
      "id": "log_failed_clone_creation",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Log broadcasts",
      "id": "log_broadcasts",
      "type": "boolean",
      "default": false
    },
    {
      "name": "Log when max list length exceeded",
      "id": "log_max_list_length",
      "type": "boolean",
      "default": true
    },
    {
      "name": "Log invalid cloud variable values",
      "id": "log_invalid_cloud_data",
      "type": "boolean",
      "default": false
    },
    {
      "name": "Animated graphs (may affect performance)",
      "id": "fancy_graphs",
      "type": "boolean",
      "default": false
    }
  ],
  "tags": []
};
export default manifest;
