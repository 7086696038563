/* generated by pull.js */
const manifest = {
  "noTranslations": true,
  "name": "Project volume slider",
  "description": "Adds a volume slider next to the green flag controls.",
  "credits": [
    {
      "name": "samq64",
      "link": "https://scratch.mit.edu/users/samq64/"
    },
    {
      "name": "GarboMuffin"
    },
    {
      "name": "DNin01"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "userstyles": [
    {
      "url": "userstyle.css"
    },
    {
      "url": "hover.css",
      "if": {
        "settings": {
          "always": false
        }
      }
    }
  ],
  "settings": [
    {
      "name": "Default volume",
      "id": "defVol",
      "type": "integer",
      "min": 0,
      "max": 100,
      "default": 100
    },
    {
      "name": "Always show slider",
      "id": "always",
      "type": "boolean",
      "default": false
    }
  ],
  "dynamicDisable": true,
  "tags": [
    "recommended"
  ]
};
export default manifest;
