/* generated by pull.js */
const manifest = {
  "name": "Enhanced full screen",
  "description": "Fixes some undesired effects in the project player's full screen mode, opens it in your browser's full screen mode, and hides the green flag toolbar.",
  "credits": [
    {
      "name": "lisa_wolfgang",
      "link": "https://scratch.mit.edu/users/lisa_wolfgang/"
    }
  ],
  "info": [
    {
      "type": "notice",
      "text": "If you choose to never show the toolbar, remember that you can use the Esc key to exit the project player's full screen mode.",
      "id": "hideToolbarNotice"
    }
  ],
  "settings": [
    {
      "dynamic": true,
      "name": "Open full screen project player in full screen browser mode",
      "id": "browserFullscreen",
      "type": "boolean",
      "default": true
    },
    {
      "dynamic": true,
      "name": "Toolbar visibility",
      "id": "toolbar",
      "type": "select",
      "potentialValues": [
        {
          "name": "Always",
          "id": "show"
        },
        {
          "name": "When hovered",
          "id": "hover"
        },
        {
          "name": "Never",
          "id": "hide"
        }
      ],
      "default": "show"
    }
  ],
  "dynamicDisable": true,
  "userstyles": [
    {
      "url": "hideOverflow.css"
    },
    {
      "url": "removeBorder.css"
    },
    {
      "url": "resizeWindow.css",
      "if": {
        "settings": {
          "toolbar": "show"
        }
      }
    },
    {
      "url": "resizeWindow_noToolbar.css",
      "if": {
        "settings": {
          "toolbar": [
            "hide",
            "hover"
          ]
        }
      }
    },
    {
      "url": "hideToolbar.css",
      "if": {
        "settings": {
          "toolbar": [
            "hide",
            "hover"
          ]
        }
      }
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "tags": [
    "beta"
  ],
  "enabledByDefault": false
};
export default manifest;
